<template>
  <div v-if="showSessionClass">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{ name: isAdmin ? 'AdminSatCourses' : 'TeacherSatCourses' }"
          >
            {{ $t("TeacherCourses.Classes Management") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="isEdit">
          {{ sessionClass.name }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <div class="ml-5">
      <el-form
        :model="sessionClass"
        ref="newTest"
        label-width="140px"
        @submit.native.prevent
        @keyup.enter.native="createNew('newTest')"
      >
        <Heading heading="h6" content="Info"> </Heading>
        <hr />
        <el-form-item
          :label="$t('TeacherCourses.Class Name')"
          required
          prop="name"
        >
          <el-input
            v-model="sessionClass.name"
            :placeholder="$t('TeacherCourses.Class Name')"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('TeacherCourses.Class Description')"
          prop="description"
        >
          <el-input
            v-model="sessionClass.description"
            type="textarea"
            :rows="5"
            :placeholder="$t('TeacherCourses.Class Description')"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Method')" prop="type">
          <el-radio-group v-model="sessionClass.is_online" size="small">
            <el-radio-button :label="0">
              <i aria-hidden="true" class="fa fa-building"></i>
              {{ $t("TeacherCourses.In-Person") }}
            </el-radio-button>
            <el-radio-button :label="1">
              <i aria-hidden="true" class="fa fa-globe"></i>
              {{ $t("TeacherCourses.Online") }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Class Dates')" prop="date">
          <el-date-picker
            style="width: 455px"
            v-model="classDate"
            type="daterange"
            range-separator="~"
            :start-placeholder="$t('TeacherCourses.Start Date')"
            :end-placeholder="$t('TeacherCourses.End Date')"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Class Time')">
          <el-time-select
            class="mr-3"
            :placeholder="$t('TeacherCourses.Start Time')"
            :picker-options="{
              start: '06:00',
              step: '00:30',
              end: '23:30'
            }"
            v-model="sessionClass.time_from"
          >
          </el-time-select>
          <el-time-select
            :placeholder="$t('TeacherCourses.End Time')"
            v-model="sessionClass.time_to"
            :picker-options="{
              start: '06:00',
              step: '00:30',
              end: '23:30',
              minTime: sessionClass.time_from
            }"
          >
          </el-time-select>
        </el-form-item>
        <Heading heading="h6" content="Roster"> </Heading>
        <hr />
        <el-form-item
          :label="$t('TeacherCourses.Teacher')"
          required
          v-if="isAdmin || isCompany"
        >
          <el-table
            v-if="teacher_users.length > 0"
            border
            :data="teacher_users"
            class="mb-3"
          >
            <el-table-column :label="$t('TeacherCourses.Teacher')" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Register Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Action')" width="100px">
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  :content="$t('TeacherCourses.Remove Teacher')"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="small"
                    @click="removeTeacher(scope.$index)"
                  >
                    <i class="fas fa-user-minus"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-tooltip
            effect="dark"
            :content="$t('TeacherCourses.Add Teacher')"
            placement="top"
          >
            <el-button type="success" size="small" @click="showTeachersWindow">
              <i class="fas fa-user-plus"></i>
            </el-button>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          :label="$t('TeacherCourses.Students')"
          prop="students"
          v-if="isEdit || isAdmin || isCompany"
        >
          <el-table
            v-if="student_users.length > 0"
            border
            :data="student_users"
            class="mb-3"
          >
            <el-table-column :label="$t('TeacherCourses.Students')" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Action')" width="100px">
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  :content="$t('TeacherCourses.Remove Student')"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="small"
                    @click="removeStudent(scope.$index)"
                  >
                    <i class="fas fa-user-minus"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-tooltip
            effect="dark"
            :content="$t('TeacherCourses.Add Student')"
            placement="top"
          >
            <!-- TODO: isTeacher 不確定需不需要 -->
            <el-button
              v-if="isAdmin || isCompany"
              type="success"
              size="small"
              @click="showStudentWindow"
            >
              <i class="fas fa-user-plus"></i>
            </el-button>
          </el-tooltip>
        </el-form-item>
        <Heading heading="h6" content="Tests"> </Heading>
        <hr />
        <el-form-item>
          <div slot="label">
            <el-popover placement="top" trigger="hover">
              <div>
                Add the Tests that you are assigning to this class. If<br />
                you set a test date, students will receive an email reminder
                about<br />
                this test 1 day in advance and at test time.
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                Tests
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </div>
          <div
            v-for="(exam, index) in classExams"
            :key="index"
            class="mb-3"
            v-show="
              classExams[index].type === 'Linear' ||
                classExams[index].type === 'Adaptive'
            "
          >
            <el-select
              style="width:170px;"
              v-model="classExams[index].type"
              class="mr-3"
              placeholder="Select Type"
              :disabled="exam.id"
            >
              <el-option
                v-for="item in fullTypes"
                :key="item.id"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              filterable
              style="width:400px;"
              v-model="classExams[index].exam_id"
              class="mr-3"
              placeholder="Select Practice"
              v-show="classExams[index].type === 'Linear'"
              :disabled="exam.id"
            >
              <el-option
                v-for="item in composePractices"
                :key="item.id"
                :label="getCompanyExamTitle(item.exam.title)"
                :value="item.exam.id"
              >
              </el-option>
            </el-select>
            <el-select
              filterable
              style="width:400px;"
              v-model="classExams[index].exam_id"
              class="mr-3"
              placeholder="Select Practice"
              v-show="classExams[index].type === 'Adaptive'"
              :disabled="exam.id"
            >
              <el-option
                v-for="item in adaptivePractices"
                :key="item.id"
                :label="getCompanyExamTitle(item.exam.title)"
                :value="item.exam.id"
              >
              </el-option>
            </el-select>
            <template v-if="false">
              <el-popover placement="top" trigger="hover">
                <div>
                  If you set a time, students will receive an email reminder
                  about<br />
                  this test 1 day in advance and at test time. If you leave
                  this<br />
                  blank, students can do this test at any time.
                </div>
                <span class="mr-2" style="cursor:pointer" slot="reference">
                  Start Time
                  <span class="text-success">
                    <i class="fas fa-question-circle"></i>
                  </span>
                </span>
              </el-popover>
              <el-date-picker
                class="mr-3"
                style="width:185px;"
                v-model="classExams[index].exam_date"
                :picker-options="pickerOptions"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                :placeholder="$t('TeacherCourses.Start Time')"
              >
              </el-date-picker>
            </template>
            <el-tooltip effect="dark" content="" placement="top">
              <div slot="content" style="width: 200px;">
                Lock explanation: Check this if you want to hide explanations
                from students when they finish the test. If you hide the
                explanations, you can manually reveal it to individual students
                later.
              </div>
              <el-checkbox
                v-model="classExams[index].hide_exp"
                :true-label="1"
                :false-label="0"
                border
                size="small"
              >
                <i class="fas fa-lock"></i>
              </el-checkbox>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              :content="$t('TeacherCourses.Remove Test')"
              placement="top"
              v-if="!exam.id"
            >
              <el-button
                class="ml-3"
                type="danger"
                @click="removeExam(index)"
                size="small"
              >
                <i class="fas fa-times"></i>
              </el-button>
            </el-tooltip>
          </div>

          <el-tooltip
            effect="dark"
            :content="$t('TeacherCourses.Add Test')"
            placement="top"
          >
            <el-button
              type="success"
              size="small"
              @click="addNewExam('Linear')"
            >
              <i class="fas fa-plus"></i>
            </el-button>
          </el-tooltip>
        </el-form-item>
        <Heading heading="h6" content="Modules & Drills"> </Heading>
        <hr />
        <el-form-item>
          <div slot="label">
            <el-popover placement="top" trigger="hover">
              <div>
                Add the Modules and Drills that you are assigning to this class.
                If<br />
                you set a test date, students will receive an email reminder
                about<br />
                this test 1 day in advance and at test time.
              </div>
              <span
                class="text-success"
                style="cursor:pointer"
                slot="reference"
              >
                Modules & Drills
                <i class="fas fa-question-circle"></i
              ></span>
            </el-popover>
          </div>
          <div
            v-for="(exam, index) in classExams"
            :key="index"
            class="mb-3"
            v-show="
              classExams[index].type === 'Modules' ||
                classExams[index].type === 'Drills'
            "
          >
            <el-select
              style="width:170px;"
              v-model="classExams[index].type"
              class="mr-3"
              placeholder="Select Type"
              :disabled="exam.id"
            >
              <el-option
                v-for="item in types"
                :key="item.id"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <!-- <el-select
              style="width:170px;"
              v-model="classExams[index].exam.status"
              class="mr-3"
              placeholder="Select Status"
              :disabled="exam.id"
            >
              <el-option label="Public" value="PUBLIC"> </el-option>
              <el-option label="Private" value="PRIVATE"> </el-option>
              <el-option label="Drafts" value="DRAFT"> </el-option>
            </el-select> -->
            <el-select
              filterable
              style="width:400px;"
              v-model="classExams[index].exam_id"
              class="mr-3"
              placeholder="Select Practice"
              v-show="classExams[index].type === 'Modules'"
              :disabled="exam.id"
            >
              <el-option
                v-for="item in modules"
                :key="item.id"
                :label="getCompanyExamTitle(item.title)"
                :value="item.id"
              >
                <span
                  v-if="classExamsIDArr.indexOf(item.id) > -1"
                  class="text-success"
                >
                  <i class="fa fa-check"></i>
                </span>
                {{ getCompanyExamTitle(item.title) }}
              </el-option>
            </el-select>
            <el-select
              filterable
              style="width:400px;"
              v-model="classExams[index].exam_id"
              class="mr-3"
              placeholder="Select Practice"
              v-show="classExams[index].type === 'Drills'"
              :disabled="exam.id"
            >
              <el-option
                v-for="item in drills"
                :key="item.id"
                :label="getCompanyExamTitle(item.title)"
                :value="item.id"
              >
                <span
                  v-if="classExamsIDArr.indexOf(item.id) > -1"
                  class="text-success"
                >
                  <i class="fa fa-check"></i>
                </span>
                {{ getCompanyExamTitle(item.title) }}
              </el-option>
            </el-select>
            <template v-if="false">
              <el-popover placement="top" trigger="hover">
                <div>
                  If you set a time, students will receive an email reminder
                  about<br />
                  this test 1 day in advance and at test time. If you leave
                  this<br />
                  blank, students can do this test at any time.
                </div>
                <span class="mr-2" style="cursor:pointer" slot="reference">
                  Start Time
                  <span class="text-success">
                    <i class="fas fa-question-circle"></i>
                  </span>
                </span>
              </el-popover>
              <el-date-picker
                class="mr-3"
                style="width:185px;"
                v-model="classExams[index].exam_date"
                :picker-options="pickerOptions"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                :placeholder="$t('TeacherCourses.Start Time')"
              >
              </el-date-picker>
            </template>
            <el-tooltip
              effect="dark"
              :content="$t('TeacherCourses.Remove Test')"
              placement="top"
              v-if="!exam.id"
            >
              <el-button type="danger" @click="removeExam(index)" size="small">
                <i class="fas fa-times"></i>
              </el-button>
            </el-tooltip>
          </div>
          <el-tooltip
            effect="dark"
            :content="$t('TeacherCourses.Add Test')"
            placement="top"
          >
            <el-button
              type="success"
              size="small"
              @click="addNewExam('Drills')"
            >
              <i class="fas fa-plus"></i>
            </el-button>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <div class="text-center">
            <hr />
            <el-button type="success" @click="createNew('newTest')">
              <span v-if="isEdit">
                {{ $t("TeacherCourses.Save Class") }}
              </span>
              <span v-else>
                {{ $t("TeacherCourses.Create Class") }}
              </span>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      append-to-body
      :title="$t('TeacherCourses.Add Student')"
      :visible.sync="showStudents"
      width="50%"
    >
      <el-form>
        <el-form-item>
          <el-select
            style="width: 100%;"
            v-model="selectedStudent"
            popper-class="select-popper"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            placeholder="Email / Name"
            :remote-method="searchUsers"
          >
            <el-option
              v-for="(student, index) in students"
              :key="student.id"
              :label="
                `${user.displayName(student.first_name, student.last_name)} [${
                  student.email
                }]`
              "
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showStudents = false">Cancel</el-button>
        <el-button type="success" @click="addStudent">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog
      append-to-body
      :title="$t('TeacherCourses.Add Teacher')"
      :visible.sync="showTeachers"
      width="50%"
    >
      <el-form>
        <el-form-item>
          <el-select
            style="width: 100%;"
            v-model="selectedTeacher"
            popper-class="select-popper"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            placeholder="Email / Name"
            :remote-method="searchTeachers"
          >
            <el-option
              v-for="(teacher, index) in teachers"
              :key="teacher.id"
              :label="
                `${user.displayName(teacher.first_name, teacher.last_name)} [${
                  teacher.email
                }]`
              "
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTeachers = false">Cancel</el-button>
        <el-button type="success" @click="addTeacher">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import { user, instant } from "@ivy-way/material";
import Class from "@/apis/class.js";
import SAT from "@/apis/sat";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import role from "@/mixins/role.js";
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < Date.now();
        }
      },
      types: ["Modules", "Drills"],
      fullTypes: ["Linear", "Adaptive"],
      showSessionClass: true,
      classDate: [],
      sessionClass: {
        name: "",
        description: "",
        is_online: 0,
        started_on: "",
        ended_on: "",
        time_from: "",
        time_to: ""
      },
      subjects: [],
      showStudents: false,
      students: [],
      student_users: [],
      old_student_users: [],
      selectedStudent: null,
      showTeachers: false,
      teachers: [],
      teacher_users: [],
      old_teacher_users: [],
      selectedTeacher: null,
      composePractices: null,
      adaptivePractices: null,
      modules: null,
      drills: null,
      practices: null,
      DrillPractices: null,
      classExams: []
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isAdmin() {
      return (
        this.$route.name === "AdminSatAddCourse" ||
        this.$route.name === "AdminEditCourse"
      );
    },
    isTeacher() {
      return (
        this.$route.name === "TeacherSatAddCourse" ||
        this.$route.name === "TeacherEditCourse"
      );
    },
    isCompany() {
      return (
        this.$route.name === "CompanySatAddCourse" ||
        this.$route.name === "CompanyEditCourse"
      );
    },
    companyId() {
      return this.getProfile.company_id;
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    myTitle() {
      return this.isEdit
        ? this.$t("TeacherCourses.Edit Class")
        : this.$t("TeacherCourses.Create Class");
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    type() {
      let type = "";
      if (this.$route.name === "SatAddPractice") {
        type = "practice";
      } else if (this.$route.name === "SatAddComposePractice") {
        type = "composePractice";
      }
      return type;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    theme() {
      return Theme.name;
    },
    classId() {
      return this.$route.params.id;
    },
    isEdit() {
      return this.$route.params.id > 0;
    },
    classExamsIDArr() {
      let arr = [];
      this.classExams.forEach(exam => {
        if (exam.exam) {
          arr.push(exam.exam.id);
        }
      });
      return arr;
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      this.showSessionClass = false;
      await this.getClass(this.$route.params.id);
    }
    await this.getComposePractices();
    await this.getAdaptivePractices();
    await this.getModules();
    await this.getDrills();
    this.searchUsers();
    this.searchTeachers();
    // if (this.isAdmin) {
    //   this.searchUsers();
    //   this.searchTeachers();
    // }
    // if (this.isCompany) {
    //   this.searchCompanyUsers();
    //   this.searchCompanyTeachers();
    // }
    if (this.isTeacher && !this.isEdit) {
      if (this.teacher_users.length === 0) {
        this.teacher_users.push(this.getProfile);
      }
    }
    this.showSessionClass = true;
  },

  methods: {
    async getComposePractices() {
      const res = await SAT.getComposePractices({
        is_new: 1,
        page: 1,
        is_public: 1,
        per_page: 100
      });
      this.composePractices = res.compose_practice.data;
    },
    async getAdaptivePractices() {
      const res = await SAT.getAdaptivePractices({
        is_new: 1,
        page: 1,
        is_public: 1,
        per_page: 100
      });
      this.adaptivePractices = res.adaptive_practices.data;
    },
    async getModules() {
      const res = await SAT.getClassPractices(this.$route.params.id, { categories: [13, 14] });
      this.modules = res.exams;
    },
    async getDrills() {
      const res = await SAT.getClassPractices(this.$route.params.id, {
        categories: [11, 12]
      });
      this.drills = res.exams;
    },
    addStudent() {
      let selectedStudent = this.students[this.selectedStudent];
      let isIn = false;
      this.student_users.forEach(student => {
        if (selectedStudent.id === student.id) {
          isIn = true;
        }
      });
      if (isIn) {
        this.$message({
          message: "Cannot be added repeatedly!",
          type: "warning"
        });
      } else {
        this.student_users.push(selectedStudent);
        this.showStudents = false;
      }
    },
    removeStudent(index) {
      console.log(index);
      this.student_users.splice(index, 1);
    },
    addTeacher() {
      let selectedTeacher = this.teachers[this.selectedTeacher];
      let isIn = false;
      this.teacher_users.forEach(student => {
        if (selectedTeacher.id === student.id) {
          isIn = true;
        }
      });
      if (isIn) {
        this.$message({
          message: "Cannot be added repeatedly!",
          type: "warning"
        });
      } else {
        this.teacher_users.push(selectedTeacher);
        this.showTeachers = false;
      }
    },
    showTeachersWindow() {
      this.showTeachers = true;
      this.selectedTeacher = null;
    },
    showStudentWindow() {
      this.showStudents = true;
      this.selectedStudent = null;
    },
    removeTeacher(index) {
      this.teacher_users.splice(index, 1);
    },
    addNewExam(type) {
      this.classExams.push({
        type: type,
        exam_id: null,
        which_week: this.classExams.length,
        exam_date: null,
        hide_exp: 0
      });
    },
    removeExam(index) {
      this.classExams.splice(index, 1);
    },
    async searchUsers(query) {
      if (this.isAdmin) {
        this.searchAdminUsers(query);
      }
      if (this.isCompany) {
        this.searchCompanyUsers(query);
      }
    },
    async searchTeachers(query) {
      if (this.isAdmin) {
        this.searchAdminTeachers(query);
      }
      if (this.isCompany) {
        this.searchCompanyTeachers(query);
      }
    },
    async searchAdminUsers(query) {
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        per_page: 50,
        sort: "name",
        page: this.page
      });
      this.students = students.users.data;
    },

    async searchCompanyUsers(query) {
      const students = await Company.getUsers({
        keyword: query !== "" ? query : null,
        per_page: 50,
        sort: "name",
        page: this.page
      });
      this.students = students.users.data;
    },
    async searchAdminTeachers(query) {
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page,
        sort: "name",
        roles: "teacher"
      });
      this.teachers = students.users.data;
    },
    async searchCompanyTeachers(query) {
      const res = await Company.listCompanyTeachers(this.companyId, {
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page
      });
      this.teachers = res.users.data;
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.sessionClass["name"] = res.session_class["name"];
      this.sessionClass["description"] = res.session_class["description"];
      this.sessionClass["is_online"] = res.session_class["is_online"];
      this.sessionClass["time_from"] = res.session_class["time_from"];
      this.sessionClass["time_to"] = res.session_class["time_to"];
      this.classDate = [
        res.session_class["started_on"] ? res.session_class["started_on"] : "",
        res.session_class["ended_on"] ? res.session_class["ended_on"] : ""
      ];
      let exams = res.session_class["session_class_exams"];
      exams.forEach(exam => {
        if (
          exam.exam.test_type === "Modules\\Practice\\Entities\\ComposePractice"
        ) {
          exam["type"] = "Linear";
        } else if (exam.exam.test_type === "App\\Entities\\AdaptivePractice") {
          exam["type"] = "Adaptive";
        } else if (
          exam.exam.test_type === "Modules\\Practice\\Entities\\Practice"
        ) {
          if ( exam.exam.category == 11 || exam.exam.category == 12) {
            exam["type"] = "Drills";
          } else {
            exam["type"] = "Modules";
          }
        }
      });
      this.classExams = exams;
      this.student_users = res.session_class["student_users"];
      this.old_student_users = JSON.parse(
        JSON.stringify(res.session_class["student_users"])
      );
      this.teacher_users = res.session_class["teacher_users"];
      this.old_teacher_users = JSON.parse(
        JSON.stringify(res.session_class["teacher_users"])
      );
    },
    createNew(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid && this.teacher_users && this.teacher_users.length > 0) {
          this.sessionClass.started_on = this.classDate[0];
          this.sessionClass.ended_on = this.classDate[1];
          let class_id = null;
          if (this.isEdit) {
            class_id = this.classId;
            await Class.updateClass(this.classId, this.sessionClass);
          } else {
            const res = await Class.createClass(this.sessionClass);
            class_id = res.session_class.id;
          }
          if (class_id && this.student_users && this.student_users.length > 0) {
            let remove_student_user_ids = [];
            this.old_student_users.forEach(old_student => {
              let inArr = false;
              this.student_users.forEach(student => {
                if (old_student.id === student.id) {
                  inArr = true;
                }
              });
              if (!inArr) {
                remove_student_user_ids.push(old_student.id);
              }
            });
            if (remove_student_user_ids.length > 0) {
              await Class.removeStudents({
                session_class_id: class_id,
                student_user_ids: remove_student_user_ids
              });
            }

            let student_user_ids = [];
            this.student_users.forEach(student => {
              student_user_ids.push(student.id);
            });
            await Class.addStudentsToClass({
              session_class_id: class_id,
              student_user_ids: student_user_ids
            });
          }
          if (class_id && this.teacher_users && this.teacher_users.length > 0) {
            let remove_teacher_user_ids = [];
            this.old_teacher_users.forEach(old_teacher => {
              let inArr = false;
              this.teacher_users.forEach(teacher => {
                if (old_teacher.id === teacher.id) {
                  inArr = true;
                }
              });
              if (!inArr) {
                remove_teacher_user_ids.push(old_teacher.id);
              }
            });
            if (remove_teacher_user_ids.length > 0) {
              await Class.removeTeachers({
                session_class_id: class_id,
                teacher_user_ids: remove_teacher_user_ids
              });
            }

            let user_ids = [];
            this.teacher_users.forEach(student => {
              user_ids.push(student.id);
            });
            await Class.addTeachersToClass({
              session_class_id: class_id,
              teacher_user_ids: user_ids
            });
          }
          if (this.classExams && this.classExams.length > 0) {
            let saveExams = [];
            this.classExams.forEach((exam, index) => {
              if (exam.exam_id > 0) {
                exam.which_week = index;
                saveExams.push(exam);
              }
            });
            if (saveExams.length > 0) {
              await Class.updateClassExams({
                session_class_id: class_id,
                session_class_exams: saveExams
              });
            }
          }
          this.$message({
            message: "Success!",
            type: "success"
          });
          this.$router.go(-1);
        } else {
          if (!this.teacher_users || this.teacher_users.length < 1) {
            this.$message.error("Choose at least one teacher!");
          } else {
            console.log("error submit!!");
          }
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .select-popper {
  z-index: 9999 !important;
  top: auto !important;
  left: auto !important;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
</style>
